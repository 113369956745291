@import url(https://p.typekit.net/p.css?s=1&k=vur1idh&ht=tk&f=15780.15782.37496.37497&a=51250922&app=typekit&e=css);
@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/697e6f/00000000000000003b9b32c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/697e6f/00000000000000003b9b32c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/697e6f/00000000000000003b9b32c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/0e034d/00000000000000003b9b32c0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/0e034d/00000000000000003b9b32c0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/0e034d/00000000000000003b9b32c0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/55f70b/00000000000000003b9b32c5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/55f70b/00000000000000003b9b32c5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/55f70b/00000000000000003b9b32c5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/234105/00000000000000003b9b32c4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/234105/00000000000000003b9b32c4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/234105/00000000000000003b9b32c4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

.tk-muli { font-family: "muli",sans-serif; }

:host {
  --ion-font-family:  muli, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: muli, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ion-page {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.logoUploader.notabsolute {
	position: static;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-top: 15px;
	border-color: #808080;
	width: 100px;
	height: 100px;
}



.titleSubseccion {
	text-align: center;
}

.itemPadding {
	--padding-start: 15px;
	--padding-end: 15px
}

.botonLineal {
	--border-radius: 0;
	--background: transparent;
	border: 2px solid #a4a4a4;
	--color: #a4a4a4;
	--padding-start: 0;
	--padding-end: 0;
	--box-shadow: none;
}

.botonLineal ion-img {
	max-width: 15px;
	margin-left: 5px;
	position: relative;
	top: 1px;
}

.botonCerrar {
	--border-radius: 0;
	--background: #a4a4a4;
	--color: #FFF;
	--padding-start: 0;
	--padding-end: 0;
	--box-shadow: none;
}

.errorMsg {
	color: red;
	font-size: 12px;
	line-height: 1;
	padding: 0 15px;
}

.realActions button {
	font-size: 17px !important;
	--font-size: 10px !important;
	color: #AAA !important;
}

.inlineButton {
	--padding-start: 0;
	--padding-end: 0;
	--padding-top: 0;
	--padding-bottom: 0;
	height: auto;
	--background: transparent;
	color: #000;
	margin: 0;
	font-size: 13px;
	height: 25px;
	position: relative;
	top: -10px;
	--box-shadow: none;
}
.text-center {
	text-align: center !important;
}
.botonLinea {
	--background: transparent !important;
}

.textExample {
	padding-left: 25px;
	padding-right: 25px;
	display: block;
	line-height: 1;
	font-style: italic;
	opacity: 0.7;
	margin-top: 5px;
	font-size: 16px;
}

.textBig textarea {
	min-height: 125px !important;
}

.circleImg {
	--border-radius: 50%;
	width: 35px;
	height: 35px;
}

.botonEditarRed {
	--background: #e3e3e3;
	color: #000;
	/* box-shadow: 0 0 5px #fff; */
	border-radius: 50%;
	width: 30px;
	height: 30px;
	--padding-start: 5px;
	--padding-end: 2px;
	--padding-top: 0;
	--padding-bottom: 2px;
	--border-radius: 50%;
	box-shadow: none;
	--box-shadow: none;
}

.headerModal {
	display: flex;
	flex-direction: row;
}

.tituloHeader {
	height: 35px;
}

.politicasContent {
	height: 100%;
	padding: 25px 20px;
	overflow-y: auto;
	width: 100%;
	text-align: justify;
}
.BotonAcepto {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	align-items: center;
	position: relative;
	top: 2px;
	white-space: nowrap;
}

.BotonAcepto button {
	background: transparent;
	color: #8fe1ce;
	padding-left: 4px;
}

.BotonAcepto button span {
	background: transparent;
	color: #8fe1ce;
	font-size: 13px;
}

.botonactivado.esmaslargo {
    width: auto;
    border-radius: 50px;
}

.botonactivado.esmaslargo input + label {
	padding-left: 15px;
	padding-right: 15px;
}
* {
	--background-activated: inherit;
}
.nuevocuadro {
	color: #000;
	text-decoration: none;
	box-shadow: none !important;
	border-width: 0 !important;
}
.botonportada {
	width: 100%;
	--border-radius: 0;
	margin: 0;
	--background: #F8F8F8;
	border-bottom: 3px solid #CECECE;
	--padding-bottom: 0;
	--padding-top: 0;
	--padding-end: 0;
	--padding-start: 0;
	position: relative;
}

.botonColumnas svg .a {
	stroke: #CECECE;
}

.botonportada ion-img {
	max-width: 50px;
}

#segundaLinea {
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
}

.logoUploaderContainer {
	position: relative;
	width: 125px;
	height: 85px;
}

.logoUploader {
	--background: #FFF;
	border: 3px solid #CECECE;
	border-radius: 50%;
	--border-radius: 50%;
	margin: 0;
	width: 125px;
	height: 125px;
	position: absolute;
	left: 0;
	top: -40px;
	--padding-bottom: 0;
	--padding-top: 0;
	--padding-end: 0;
	--padding-start: 0;
	overflow: hidden;
	background: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tituloPrincipal {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 22px;
	font-family: muli, sans-serif;
	margin-top: 10px;
	text-align: center;
	font-weight: bold;
}

#InfoCategories {
	display: flex;
	justify-content: space-evenly;
	text-align: center;
}

.totales h3 {
	text-transform: uppercase;
	color: #757575;
	line-height: 1;
	margin: 0;
	font-size: 8px;
	font-family: muli, sans-serif;
	font-weight: 600;
}

.totales p {
	margin: 0;
	margin-top: 0px;
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
	font-family: muli, sans-serif;
}
.filtroColumnas {
	display: flex;
	justify-content: space-around;
	padding-left: 16px;
	padding-right: 16px;
	align-items: center;
	border: 2px solid #e2e6ec;
	border-left-width: 0;
	border-right-width: 0;
	margin: 10px auto 10px;
}

.botonColumnas {
	background: transparent;
	--background: transparent;
	border: 0;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0;
	outline: none !important;
	--border-radius: 50%;
	overflow: hidden;
	box-shadow: none;
	--box-shadow: none;
	--padding-start: 2px;
	--padding-end: 2px;
	--padding-top: 2px;
	--padding-bottom: 2px;
}

.botonColumnas.tres svg {
	max-width: 28%;
}

.botonColumnas.activo {
	border: 1px solid #f0f0f0;
}

.logoActual {
	width: 100%;
	position: static;
	border-radius: 50%;
	--border-radius: 50%;
	height: 125px;
	width: 125px;
}

.botonportada .portadaActual {
	width: 100%;
	max-width: none;
	opacity: 1;
}

.uploaderInput {
	display: none !important;
}

#iconosDerecha {
	display: flex;
	justify-content: center;
	align-items: center;
}

#iconosDerecha a {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2px;
}
#TiendaWhatsContainer {
	display: flex;
	align-items: center;
}

#TiendaWhats {
	background: #CECECE;
	border-radius: 50px;
	line-height: 12px;
	font-size: 11px;
	max-width: 90px;
	text-align: center;
	font-weight: bold;
	color: #FFF;
	padding: 4px 0;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	min-width: 90px;
	font-family: muli, sans-serif;
}

.ChangeTitle {
	margin: 0;
	margin-bottom: -15px;
	--background: tr;
	--box-shadow: none;
	display: block;
	max-width: 55px;
	margin-left: auto;
	margin-right: 20px;
	margin-top: -20px;
}

ion-input.tituloPrincipal {
	border-bottom: 1px solid #000;
	margin-bottom: 15px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

.botonGuardarTitulo {
	--border-radius: 0;
	width: 100%;
	--background: tra;
	line-height: 1;
	--line-height: 1;
	max-width: 191px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	color: #a4a4a4;
	border: 2px solid #a4a4a4;
	border-radius: 50px;
	font-weight: bold;
	height: 45px;
	font-size: 14px;
}

.botonGuardarTitulo ion-img {
	max-width: 20px;
	margin-left: 5px;
}

.hiddenforce {
	display: none !important;
}

.categoriesContPrincipal {
	overflow: hidden;
}
.categoriesContainer {
	padding-top: 0;
	display: flex;
	overflow: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	padding-left: 5px;
	padding-right: 5px;
}
.categoryItem {
	min-width: 90px;
	max-width: 90px;
	position: relative;
	display: inline-block;
	padding-right: 0;
	text-align: center;
	outline: none;
	/* height: auto; */
	--background: transparent;
	--box-shadow: none;
	overflow: hidden;
	--padding-start: 0;
	--padding-end: 0;
	margin: 0;
	border-radius: 50%;
	min-height: 90px;
	max-height: 90px;
	margin-right: 20px;
}

.imagenCategory {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	object-fit: cover;
	background: #f5f5f5;
	overflow: hidden;
}

ion-button.categoryItem:not(.editable) .imagenCategory {
	object-fit: fill;
	width: 80px;
	height: 80px;
	overflow: hidden;
	border-radius: 50%;
}

.categoryTitle {
	font-size: 14px;
	color: gray;
	text-align: center;
	padding-right: 12px;
	line-height: 1.5;
	margin: 0;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	max-width: 95px;
	font-family: muli, sans-serif;
}

.productosPrincipal {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 1%;
	padding-right: 1%;
	margin-top: 15px;
}
.una.productosPrincipal {
    padding-left: 10px;
    padding-right: 10px;
}
.productosPrincipal.admin {
	margin-top: 10px;
	margin-bottom: 40px;
}
.nuevocuadro {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 2%;
	margin-top: 2%;
}

.dos .nuevocuadro {
	width: 46%;
	margin-left: 2%;
	margin-right: 2%;
	margin-top: 1.5%;
	margin-bottom: 1.5%;
}

.tres .nuevocuadro {
	width: 32%;
	margin-top: 1.5%;
	margin-bottom: 1.5%;
}

.cuadrosFinales {
	min-height: 385px;
}

.dos .cuadrosFinales {
	min-height: 225px;
}

.tres .cuadrosFinales {
	min-height: 165px;
}

.imgcontainer {
	width: 100%;
	position: relative;
	box-shadow: 0 0 2px rgba(52,58,67,.1),0 2px 5px rgba(52,58,67,.08),0 5px 15px rgba(52,58,67,.08);
	border-radius: 4px;
	overflow: hidden;
}

.imgcontainer:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.imgcontainer ion-img {
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	object-fit: contain;
	object-position: center;
}

.contenedorCuadrado {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logoUploader.cuadrado {
	margin: 0;
	--border-radius: 10px;
	border-width: 0;
	--box-shadow: none;
	width: 100%;
	height: 100%;
}

.logoUploader.square {
	border-radius: 10px;
	--border-radius: 10px;
	border-width: 0;
	width: 150px;
	height: 150px;
	border-width: 1px;
	--box-shadow: none;
}

.inlineButton.rect {
	top: 0;
	font-size: 15px;
}
.sinBorde {
	--inner-border-width: 0;
}

.mt-5 {
	margin-top: 40px;
}

.contenedorAtributos {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 15px;
}

.contenedorAtributos > ion-item {
	flex: 1 1;
	--inner-border-width: 0;
}

.botonRedondo {
	border-radius: 50%;
	display: flex;
	background: #f9f9f9;
	padding: 15px 10px 10px;
	position: relative;
	top: -20px;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	max-width: 60px;
	max-height: 60px;
}
.botonRedondo.sinTop {
	top: 0;
}

.alertdanger {
	padding-left: 15px;
	padding-right: 15px;
	color: #e63333;
	font-size: 12px;
	line-height: 1;
}

.itemTitleCombi {
	width: 100%;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #a4a4a4;
	text-align: center;
	line-height: 1;
	height: auto !important;
	max-width: 40%;
}

.inputQuantity ion-input {
	border: 2px solid #a4a4a4;
	width: 65px;
	--padding-start: 5px !important;
	--padding-end: 0;
}

#realQtyCont {
	width: 100%;
}

.logoUploader.cuadrado.item ion-img {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	object-fit: fill;
}

.logoUploader.cuadrado ion-icon {
	color: #858585;
	font-size: 50px;
	width: 40px;
	text-align: center;
	display: flex;
	justify-content: center;
	position: absolute;
	background: #FFF;
	height: 40px;
	border-radius: 50%;
	padding: 10px;
	box-shadow: 0 0 5px #858585;
	top: 15%;
}

.infoContentEdit {
	position: absolute;
	width: 100%;
	bottom: 0;
	background: rgb(35, 35, 47);
}

.infoContentEdit h4 {
	font-size: 12px;
	margin: 5px 0 3px;
	text-align: left;
	padding: 0 10px;
	font-weight: normal;
}

.infoContentEdit h5 {
	margin: 5px 0 4px;
	line-height: 1;
	font-size: 10px;
	text-align: left;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
}

.separadorViewAll {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 1%;
	padding-right: 1%;
	margin-top:15px;
}

.separadorViewAll h3 {
	margin: 0;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 1;
}

.botonsombreado {
	box-shadow: none;
	border-radius: 50px;
	--ion-color-light: #FFF;
	text-transform: none;
	--border-radius: 50px;
	--box-shadow: 0 0 5px #d2d2d2;
}

.inforproduct h4 {
	margin-top: 8px;
	margin-bottom: 3px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
	padding: 0 2px;
	font-size: 17px;
	line-height: 1.5;
	font-family: muli, sans-serif;
	color: #444;
	font-weight: 600;
}

.inforproduct h5 {
	padding: 0 2px;
	color: #000;
	font-weight: bold;
	font-size: 15px;
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	font-family: muli, sans-serif;
}

.inforproduct h5 .descuento {
	margin-left: 5px;
	font-size: 13px;
	font-weight: normal;
	position: relative;
	color: #AEB5C0;
	font-weight: normal;
}

.inforproduct h5 .descuento:before {
	content: "";
	width: 100%;
	height: 1px;
	background: #AEB5C0;
	position: absolute;
	top: 50%;
}

.tres .inforproduct h5 span {
	font-size: 12px;
}
.tres .inforproduct h5 .descuento {
	display: inline-block;
	margin-left: 2px;
	font-size: 12px;
	letter-spacing: -1px;
}

@media (max-width: 325px) {
	.tres .inforproduct h5 .descuento {
		margin-left: 1px;
	}
}

.tres.productosPrincipal {
	justify-content: flex-start;
}

.tres.productosPrincipal > a {
	margin: 0 0.666%;
}

.whatsappButtonGlobal {
	width: 42px;
	height: 42px;
	background: rgb(48,191,57);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 25px;
	overflow: hidden;
	line-height: 1;
	color: #FFF;
}

.micatalogoButton, .micatalogoButton svg {
	width: 42px;
	height: 42px;
}

.svgCircle {
    fill:none;
    stroke:#8a3ab9;
    stroke-linecap: round;
    stroke-width:2;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: stroke-draw 3s ease-out 1 alternate; 
	position: absolute;
	width: 110px;
	height: 110px;
  }

@keyframes stroke-draw {
  from{
    stroke-dasharray: 1;
  }
  to{
    transform:rotate(180deg);
    stroke-dasharray: 8;
  }
}

.subCategorias1 {
	display: flex;
	padding-top: 0;
	display: flex;
	overflow: scroll;
	overflow-y: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	padding-left: 5px;
	padding-right: 5px;
	box-shadow: 0 3px 6px #e6e6e6;
	margin-bottom: 25px;
	margin-top: 15px;
	border-top: 2px solid #e6e6e6;
}

@media (max-width: 991px) {
	.subCategorias1 {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

.subCategorias1 + .subCategorias1 {
	margin-top: -25px;
}
@media (max-width: 991px) {
	.subCategorias1::-webkit-scrollbar,
	.categoriesContainer::-webkit-scrollbar {
		display: none;
	}
}

.subCategorias1 > a {
	display: flex;
	padding: 0;
	margin: 0;
}

.subCategorias1 > a {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	height: 45px;
	padding: 0 20px;
	position: relative;
	justify-content: center;
	list-style: none;
	background: #FFF;
	border-width: 0;

	text-align: center;
	outline: none;
	--background: transparent;
	--box-shadow: none;
	overflow: hidden;
	--padding-start: 0;
	--padding-end: 0;
	margin: 0;
	color: gray;
	font-size: 14px;
	font-family: muli, sans-serif;
	text-decoration: none !important;
}

.subCategorias1 > a:before {
	content: "";
	display: block;
	height: 3px;
	width: 0%;
	background-color: rgb(127, 127, 127);
	position: absolute;
	bottom: 0;
	right: 0;
	left: auto;
	transition: all .5s;
}

.subCategorias1 > a.activo:before {
	width: 100%;
	left: 0;
	right: 0;
	transition: all .5s;
	-webkit-transition: all .5s;
}

.Subcategorias0 {
	background: #FFF;
	z-index: 10000;
	position: sticky;
	top: 0;
}


.subCats h4, .subCats h5 {
	padding-left: 10px;
	padding-right: 10px;
    font-family: 'muli', sans-serif;
    margin: 0 auto 15px;
    line-height: 1.5;
}

.subCats h4 {
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgb(226, 226, 226);
	border-top: 1px solid rgb(226, 226, 226);
}

.subCats h5 {
	margin-bottom: 5px;
}

.alertanohay {
	margin-left: 10px;
	margin-right: 10px;
	background: rgb(226,226,226);
	color: #000;
	font-family: 'muli', sans-serif;
	margin-bottom: 25px;
	display: flex;
	height: 45px;
	padding: 0 10px;
	align-items: center;
}

.mostradod {
	display: flex !important;
}

.hidden {
	display: none;
}

.nuevoProductoContainer h2 {
	font-size: 20px;
	font-family: 'muli', sans-serif;
	padding: 0 10px;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
	color: #7f7f7f;
}

.nuevoProductoContainer h3 {
	font-size: 16px;
	padding: 0 10px;
	margin: 5px 0 20px;
	color: #7f7f7f;
}

.alerta {
	margin: -15px 10px 25px;
    background: #ececec;
    padding: 15px 10px;
    border-radius: 5px;
    font-family: 'muli', sans-serif;
    line-height: 1;
}

.alerta.grande {
    margin-top: 5px;
    margin-bottom: 15px;
}


.fixedReal ion-fab-button {
	height: 40px;
	width: 40px;
	--background: rgba(0,0,0,0.5);
}

.fixedReal ion-fab-list ion-fab-button {
	--background: #FFF !important;
	--color: #000;
}

.fixedReal ion-fab-button ion-icon {
	font-size: 24px;
}

.fixedReal ion-fab-list {
	margin: 50px 0 0;
	left: -7px;
}
#nombreProducto {
	font-size: 25px;
	margin-top: 15px;
	font-weight: bold;
	font-family: muli, sans-serif;
	font-weight: 600;
	color: #444;
	margin-bottom: 0;
	line-height: 1.2;
}

#productPrice {
	font-size: 1.5rem;
	font-family: "mr-eaves-xl-modern",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	font-weight: 400;
	line-height: 1.235;
	margin-top: 0;
}

#referenceProduct {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 15px;
	font-weight: normal;
}

.infoText {
	padding-left: 5px;
	padding-right: 5px;
}

.formCombis {
	padding: 0 5px;
	margin-top: 15px;
}

#submitForm {
	width: 100%;
	color: #FFF;
	box-shadow: none;
	border-radius: 50px;
	height: 60px;
	margin-top: 15px;
	margin-bottom: 20px;
	font-family: muli, sans-serif;
	font-weight: 600;
	font-size: 17px;
}

#submitForm ion-icon {
	font-size: 25px;
	margin-right: 5px;
}

.attributesDisponibles {
	margin-bottom: 15px;
}

.attributesDisponibles h4 {
	margin-top: 0;
	margin-bottom: 3px;
	font-size: 18px;
	font-family: muli, sans-serif;
	font-weight: bold;
	color: #444;
}

.attributesDisponibles h4 > span {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	margin-top: 35px;
}

.attributesDisponibles h4 > span .obligatorioLabel {
	font-size: 12px;
	font-weight: 100;
	border: 1px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 1.75;
	padding-top: 3px;
	border-radius: 25px;
	color: #000;
}

.attributesDisponibles h4 small {
	display: block;
	font-weight: 300;
	font-size: 12px;
	color: #000;
}

.itemOpciones {
	--padding-start: 0;
}

.accordionMargin {
	margin-top: 5px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	margin-bottom: 25px !important;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12) !important;
}

.botonactivado {
	padding: 0;
	color: #000;
	position: relative;
	border: 2px solid #000;
	width: 45px;
	height: 45px;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 15px;
	margin-right: 10px;
	overflow: hidden;
}

.botonactivado label {
	width: 100%;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-family: muli, sans-serif;
	font-weight: 600;
}

.formQuantityAdicion {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.formQuantityAdicion > * {
	height: 30px;
	display: flex !important;
	align-items: center !important;
}

.formQuantityAdicion > span {
	padding-top:2px !important;
}
.labelTag.tienePrecioExtra {
	display: block;
	padding-top: 8px;
	padding-bottom: 8px;
}

.iconToUp:disabled {
	opacity: .5;
}

.listaUl {
	padding-left: 20px;
	margin: 0;
	margin-bottom: 15px;
	list-style-type: square;
	font-size: 12px;
	color: rgba(0,0,0,0.5);
	font-family: muli, sans-serif !important;
}

.listaUl li span {
	margin-left: 10px;
}

.listaUl li span + span {
	border-left: 1px solid #000;
	padding-left: 10px;
}

.listaUl li {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.botonactivado input {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.labelTag {
	font-size: 14px;
	color: #000;
	opacity: 0.7;
	padding-left: 5px;
}
.extraPrice {
	display: block;
	font-weight: 700;
	font-size: 15px;
	font-family: muli, sans-serif !important;
	color: #000;
	opacity: 0.85;
	padding-left: 5px;
	margin-top: 5px;
	height: 17px;
}

.headerFixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10px 5px;
	z-index: 1000;
	max-width: 100%;
	max-width: 560px;
	left: 50%;
	transform: translateX(-50%);
}

.headerFixed.home {
	justify-content: flex-end
}
.fixedReal {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 1.5%;
	padding-right: 1.5%;
	position: fixed;
	z-index: 10000;
	padding-top: 5px;
	max-width: 600px;
	flex-direction: row-reverse;
}
.backButton, .cartButton {
	background: rgba(0,0,0,0.5);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #FFF;
	font-size: 1.3rem;
}

.cartButton {
	position: relative;
}

.overexposed {
	position: absolute;
	background: #000;
	width: 20px;
	height: 20px;
	font-size: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid #CACACA;
	right: -3px;
	top: -3px;
	color: #FFF;
}

.tituloCart {
	margin-top: 20px !important;
	margin-bottom: 0 !important;
}

.backButton.blanco {
	background: #f5f5f5;
	box-shadow: 5px 5px 10px #dddddd, -5px -5px 10px #ffffff;
	color: #000;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.containerCarts {
	margin-top: 20px;
}

.cardNueva {
	border-bottom: 2px solid #f4f4f4;
	margin-bottom: 15px;
	padding-bottom: 15px;
}

.cartImg {
	width: 80px;
	height: 80px;
	object-fit: contain;
	border-radius: 8px;
	overflow: hidden;
}

.cartExample {
	width: 80px;
	height: 80px;
	border-radius: 8px;
	overflow: hidden;
	margin-top: 0;
	margin-bottom: 0;
}
@media (min-width: 350px) {
	.cartImg, .cartExample {
		width: 95px;
		height: 95px;
	}
}

.pull-to-right {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;
}

.iconToUp {
	margin: 0;
	width: 22px;
	height: 22px;
	font-size: 14px;
	display: flex;
	text-align: center;
	padding: 0;
	border-radius: 50%;
	box-shadow: none;
	line-height: 1;
	justify-content: center;
	align-items: center;
	background: #FFF;
	border: 1px solid #000;
	color: #000;
}

.finalPrice {
	font-weight: bold;
}

.formToUp {
	min-width: 110px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.quantityInfo {
	line-height: 1;
	position: relative;
	display: inline-block;
	margin: 0 10px;
	font-family: muli, sans-serif;
	top: -1px;
}

.containerForCartInfo {
	position: relative;
}
.botonEliminar {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 5px;
	color: #e33371;
	padding: 0;
	font-size: 24px !important;
	box-shadow: none;
	background: transparent;
}

.addmorecombinations {
	border: 1px solid #3880ff;
	text-decoration: none;
	text-align: center;
	line-height: 2;
	border-radius: 5px;
	display: flex;
	margin-top: 10px;
	width: 100%;
	max-width: 115px;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.padding-16 {
	padding-bottom: 16px !important;
}

.cardProduct {
	margin-bottom: 15px;
	box-shadow: 8px 8px 16px #ddd,-8px -8px 16px #fff !important;
}

.cardProduct.InfoDetail {
	margin-top: 30px;
}

.footerInfo {
	padding: 10px 15px;
}
.margin-top-25 {
	margin-top: 25px;
}

.cartfooterbutton {
	height: 45px;
	background: #404040;
	background-image: none;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	border-radius: 5px;
	font-weight: bold;
	box-shadow: none;
	border-radius: 50px;
	color: #FFF;
	font-family: muli, sans-serif;
}

.cartfooterbutton ion-icon {
	font-size: 24px;
	margin-right: 5px;
}

.whatsappButton {
	color: #FFF;	
	background: #21c59f;
}

.whatsappButton.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.addToCart {
	width: 100%;
	text-align: center;
	display: flex;
	background: #bdbdbd;
	height: 38px;
	margin-bottom: 20px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	font-size: 17px;
	color: #FFF;
	box-shadow: none !important;
	font-family: muli, sans-serif;
}

.addToCart ion-icon {
	margin-right: 4px;
	font-size: 22px;
}
section.productInfo {
    padding-left: 10px;
    padding-right: 10px;
}

.headerProd img {
	height: 30px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
}

.headerProd ion-toolbar {
	--border-width: 0 !important;
	margin-bottom: 0;
	--background: transparent;
	--ion-color-base: transparent !important;
}


.headerProd ion-back-button {
	color: #000;
	font-size: 15px;
}

.cartHeader {
	color: #000 !important;
	font-size: 25px !important;
	width: auto !important;
	min-width: 1px !important;
	padding: 0 !important;
	position: relative;
	top: 2px;
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background: #bbbaba;
	opacity: 0.5;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: #c94edb;
	opacity: 1;
}

.swiper-container {
	overflow: initial;
}

.swiper-pagination.swiper-pagination-bullets {
	bottom: -25px;
}

#precioProducto {
	font-weight: bolder;
	font-size: 18px;
	margin-top: 0;
	line-height: 1;
	display: block;
}

#precioProducto small {
	margin-left: 10px;
	font-size: 17px;
	position: relative;
	font-weight: 600;
	color: #999;
}

#productPrice {
	margin-bottom: 3px;
}


#precioProducto small:before {
	content: "";
	width: 100%;
	height: 1px;
	background: #999;
	display: block;
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.descriptionText {
	text-align: justify;
	font-size: 13px !important;
	font-weight: 300 !important;
	margin-top: 7px !important;
	color: #444 !important;
	line-height: 22px !important;
	max-height: 88px;
	overflow: hidden;
}
.descriptionText.sinlimite {
	max-height: none;
}
.descriptionText.abierto {
	max-height: none;
}

#productPrice {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: muli, sans-serif;
	color: #444;
}

.headerProd {
	padding-left: 5px;
	padding-right: 5px;
}

.MuiButtonGroup-contained {
	box-shadow: none;
}

.inputCirculos {
	display: flex;
	width: 100%;
}

.selectColor {
	width: 100%;
	height: 40px;
	border-radius: 50px;
	border: 2px solid #000;
	padding: 0 10px;
}


.textoCuantosCarritos {
	padding-left: 15px;
	padding-right: 15px;
	font-size: 13px !important;
	color: #CCC;
}

.tituloname {
	font-weight: bold;
	line-height: 1.2;
}

.infomini {
	font-size: 12px !important;
	font-weight: normal !important;
	color: rgba(0,0,0,0.5) !important;
}

.MuiFormControl-root {
	width: 100%;
}
.MuiFormControl-root + .MuiFormControl-root {
	margin-top: 35px;
	width: 100%;
}

.nextTo {
    min-width: 70%;
    height: 40px;
    position: absolute;
    top: 10px;
    border-width: 0;
    border-left: 1px solid #c4c4c4;
    padding-left: 15px;
    right: 5px;
    background: #FFF;
    z-index: 10;
    max-width: 70%;
    outline: none;
    -webkit-appearance: none;
}

.width100 {
	width: 100%;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #444 !important;
}

.MuiFormLabel-colorSecondary.Mui-focused {
	color: #444 !important;
}

.infoAsesor, .infoAsesor span {
	margin-top: 35px;
	font-size: 14px;
	color: #888;
	line-height: 1.25;
	overflow: hidden;
}

.infoAsesor span {
	margin-top: 0;
}

.MuiFormControlLabel-root {
	margin-right: 0;
}

.infoAsesor svg {
	float: left;
	margin-right: 10px;
}

button.cartfooterbutton {
	width: 100%;
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 15px;
}

button.cartfooterbutton:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.cartHeader .overexposed {
	right: -4px;
	width: 18px;
	height: 18px;
	font-size: 10px;
	top: -5px;
}

.slick-dots li button:before {
	font-size: 10px !important;
}
.slick-dots {
	bottom: 10px !important;
}

.botonactivado input:checked + label {
	background: #d7d6d6;
	border-color: #d7d6d6;
	width: 250%;
	height: 150%;
	max-width: none;
	display: flex;
	color: #000;
}

.alert-button-group * {
	color: #000;
}

.alert-radio-inner {
	border-color: #000 !important;
}

[aria-checked="true"] .alert-radio-label {
	color: #000 !important;
}

.modal-wrapper {
	min-height: 100% !important;
	--border-radius: 0;
}

.contenedorSlider {
	margin-left: -15px;
	margin-right: -15px;
}


.contenedorSlider ion-fab > ion-fab-button {
    --background: #000;
    --box-shadow: none;
    width: 40px;
    --color: #fff;
    height: 40px;
}

.contenedorSlider ion-fab > ion-fab-button ion-icon {
    font-size: 25px;
    position: relative;
    left: -2px;
}

.contenedorSlider ion-fab-list {
	right: -7px;
    margin-top: 50px;
}

.contenedorSlider ion-fab-list ion-fab-button {
	--background: #FFF !important;
	--color: #000;
}

.react-share__ShareButton {
	font-size: 22px !important;
	display: flex;
}

.MuiTypography-body1,
.MuiTypography-body2,
.MuiTypography-h3,
.MuiTypography-h2,
.MuiTypography-h1,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
	font-family: muli, sans-serif !important;
	color: #444;
}


.buscadorContainer {
	position: relative;
}

.buscadorContainer ion-icon {
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
}

.totales {
	height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 15%;
}

.totales.buscador {
	min-width: 65%;
}

#InfoCategories > .totales:first-child {
    min-width: 20% !important;
}

.buscadorContainer input {
	height: 40px;
	border-radius: 50px;
	border: 1px solid #444;
	padding-left: 40px;
	width: 100%;
	font-size: 13px;
}

.filtros {
	background: transparent;
	border-width: 0;
	font-size: 22px;
	padding-left: 0;
	padding-right: 0;
}

.totales ion-label {
    font-size: 20px !important;
	margin-right:0 !important;
}
.totales ion-select {
	position: absolute;
	opacity: 0;
	left: -15px;
	width: 50px;
	max-width: none;
	padding-left: 0;
	padding-right: 0;
	z-index: 100;
}

.totales ion-label {
	text-align: center;
}


ion-select-popover ion-label {
	font-size: 12px !important;
}

.ContainerForButtonViewMore ion-button {
	--background: #FFF;
	padding-left: 25px;
	padding-right: 1px;
}
br {
	display: block;
}
.ContainerForButtonViewMore {
	text-align: right;
	margin-top: -2px;
	position: relative;
}
.ContainerForButtonViewMore ion-button:before {
	content: "";
	width: 100%;
	height: 1px;
	background: #dfdfdf;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.nohayproductos {
	width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
	margin-top: 15%;
}

.textareaProducto {
	width: 100%;
	border-radius: 4px;
	border: 1px solid #000;
	padding: 10px 10px;
	resize: none;
	height: 60px;
	box-shadow: none !important;
	outline: none !important;
}
.descriptionText.homepage {
	line-height: 15px !important;
	font-size: 12px !important;
	height: 30px;
	text-align: justify !important;
	margin-top: 0 !important;
	margin-bottom: 10px !important;
}
