@import url("https://p.typekit.net/p.css?s=1&k=vur1idh&ht=tk&f=15780.15782.37496.37497&a=51250922&app=typekit&e=css");

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/697e6f/00000000000000003b9b32c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/697e6f/00000000000000003b9b32c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/697e6f/00000000000000003b9b32c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/0e034d/00000000000000003b9b32c0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/0e034d/00000000000000003b9b32c0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/0e034d/00000000000000003b9b32c0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/55f70b/00000000000000003b9b32c5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/55f70b/00000000000000003b9b32c5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/55f70b/00000000000000003b9b32c5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;
}

@font-face {
font-family:"muli";
src:url("https://use.typekit.net/af/234105/00000000000000003b9b32c4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/234105/00000000000000003b9b32c4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/234105/00000000000000003b9b32c4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}

.tk-muli { font-family: "muli",sans-serif; }

:host {
  --ion-font-family:  muli, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: muli, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ion-page {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.logoUploader.notabsolute {
	position: static;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-top: 15px;
	border-color: #808080;
	width: 100px;
	height: 100px;
}



.titleSubseccion {
	text-align: center;
}

.itemPadding {
	--padding-start: 15px;
	--padding-end: 15px
}

.botonLineal {
	--border-radius: 0;
	--background: transparent;
	border: 2px solid #a4a4a4;
	--color: #a4a4a4;
	--padding-start: 0;
	--padding-end: 0;
	--box-shadow: none;
}

.botonLineal ion-img {
	max-width: 15px;
	margin-left: 5px;
	position: relative;
	top: 1px;
}

.botonCerrar {
	--border-radius: 0;
	--background: #a4a4a4;
	--color: #FFF;
	--padding-start: 0;
	--padding-end: 0;
	--box-shadow: none;
}

.errorMsg {
	color: red;
	font-size: 12px;
	line-height: 1;
	padding: 0 15px;
}

.realActions button {
	font-size: 17px !important;
	--font-size: 10px !important;
	color: #AAA !important;
}

.inlineButton {
	--padding-start: 0;
	--padding-end: 0;
	--padding-top: 0;
	--padding-bottom: 0;
	height: auto;
	--background: transparent;
	color: #000;
	margin: 0;
	font-size: 13px;
	height: 25px;
	position: relative;
	top: -10px;
	--box-shadow: none;
}
.text-center {
	text-align: center !important;
}
.botonLinea {
	--background: transparent !important;
}

.textExample {
	padding-left: 25px;
	padding-right: 25px;
	display: block;
	line-height: 1;
	font-style: italic;
	opacity: 0.7;
	margin-top: 5px;
	font-size: 16px;
}

.textBig textarea {
	min-height: 125px !important;
}

.circleImg {
	--border-radius: 50%;
	width: 35px;
	height: 35px;
}

.botonEditarRed {
	--background: #e3e3e3;
	color: #000;
	/* box-shadow: 0 0 5px #fff; */
	border-radius: 50%;
	width: 30px;
	height: 30px;
	--padding-start: 5px;
	--padding-end: 2px;
	--padding-top: 0;
	--padding-bottom: 2px;
	--border-radius: 50%;
	box-shadow: none;
	--box-shadow: none;
}

.headerModal {
	display: flex;
	flex-direction: row;
}

.tituloHeader {
	height: 35px;
}

.politicasContent {
	height: 100%;
	padding: 25px 20px;
	overflow-y: auto;
	width: 100%;
	text-align: justify;
}
.BotonAcepto {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 12px;
	align-items: center;
	position: relative;
	top: 2px;
	white-space: nowrap;
}

.BotonAcepto button {
	background: transparent;
	color: #8fe1ce;
	padding-left: 4px;
}

.BotonAcepto button span {
	background: transparent;
	color: #8fe1ce;
	font-size: 13px;
}

.botonactivado.esmaslargo {
    width: auto;
    border-radius: 50px;
}

.botonactivado.esmaslargo input + label {
	padding-left: 15px;
	padding-right: 15px;
}