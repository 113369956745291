#nombreProducto {
	font-size: 25px;
	margin-top: 15px;
	font-weight: bold;
	font-family: muli, sans-serif;
	font-weight: 600;
	color: #444;
	margin-bottom: 0;
	line-height: 1.2;
}

#productPrice {
	font-size: 1.5rem;
	font-family: "mr-eaves-xl-modern",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	font-weight: 400;
	line-height: 1.235;
	margin-top: 0;
}

#referenceProduct {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 15px;
	font-weight: normal;
}

.infoText {
	padding-left: 5px;
	padding-right: 5px;
}

.formCombis {
	padding: 0 5px;
	margin-top: 15px;
}

#submitForm {
	width: 100%;
	color: #FFF;
	box-shadow: none;
	border-radius: 50px;
	height: 60px;
	margin-top: 15px;
	margin-bottom: 20px;
	font-family: muli, sans-serif;
	font-weight: 600;
	font-size: 17px;
}

#submitForm ion-icon {
	font-size: 25px;
	margin-right: 5px;
}

.attributesDisponibles {
	margin-bottom: 15px;
}

.attributesDisponibles h4 {
	margin-top: 0;
	margin-bottom: 3px;
	font-size: 18px;
	font-family: muli, sans-serif;
	font-weight: bold;
	color: #444;
}

.attributesDisponibles h4 > span {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
	margin-top: 35px;
}

.attributesDisponibles h4 > span .obligatorioLabel {
	font-size: 12px;
	font-weight: 100;
	border: 1px solid #000;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 10px;
	padding-right: 10px;
	line-height: 1.75;
	padding-top: 3px;
	border-radius: 25px;
	color: #000;
}

.attributesDisponibles h4 small {
	display: block;
	font-weight: 300;
	font-size: 12px;
	color: #000;
}

.itemOpciones {
	--padding-start: 0;
}

.accordionMargin {
	margin-top: 5px !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
	margin-bottom: 25px !important;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12) !important;
}

.botonactivado {
	padding: 0;
	color: #000;
	position: relative;
	border: 2px solid #000;
	width: 45px;
	height: 45px;
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 15px;
	margin-right: 10px;
	overflow: hidden;
}

.botonactivado label {
	width: 100%;
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	height: 100%;
	font-family: muli, sans-serif;
	font-weight: 600;
}

.formQuantityAdicion {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.formQuantityAdicion > * {
	height: 30px;
	display: flex !important;
	align-items: center !important;
}

.formQuantityAdicion > span {
	padding-top:2px !important;
}
.labelTag.tienePrecioExtra {
	display: block;
	padding-top: 8px;
	padding-bottom: 8px;
}

.iconToUp:disabled {
	opacity: .5;
}

.listaUl {
	padding-left: 20px;
	margin: 0;
	margin-bottom: 15px;
	list-style-type: square;
	font-size: 12px;
	color: rgba(0,0,0,0.5);
	font-family: muli, sans-serif !important;
}

.listaUl li span {
	margin-left: 10px;
}

.listaUl li span + span {
	border-left: 1px solid #000;
	padding-left: 10px;
}

.listaUl li {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.botonactivado input {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.labelTag {
	font-size: 14px;
	color: #000;
	opacity: 0.7;
	padding-left: 5px;
}
.extraPrice {
	display: block;
	font-weight: 700;
	font-size: 15px;
	font-family: muli, sans-serif !important;
	color: #000;
	opacity: 0.85;
	padding-left: 5px;
	margin-top: 5px;
	height: 17px;
}

.headerFixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10px 5px;
	z-index: 1000;
	max-width: 100%;
	max-width: 560px;
	left: 50%;
	transform: translateX(-50%);
}

.headerFixed.home {
	justify-content: flex-end
}
.fixedReal {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 1.5%;
	padding-right: 1.5%;
	position: fixed;
	z-index: 10000;
	padding-top: 5px;
	max-width: 600px;
	flex-direction: row-reverse;
}
.backButton, .cartButton {
	background: rgba(0,0,0,0.5);
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: #FFF;
	font-size: 1.3rem;
}

.cartButton {
	position: relative;
}

.overexposed {
	position: absolute;
	background: #000;
	width: 20px;
	height: 20px;
	font-size: 13px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid #CACACA;
	right: -3px;
	top: -3px;
	color: #FFF;
}

.tituloCart {
	margin-top: 20px !important;
	margin-bottom: 0 !important;
}

.backButton.blanco {
	background: #f5f5f5;
	box-shadow: 5px 5px 10px #dddddd, -5px -5px 10px #ffffff;
	color: #000;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.containerCarts {
	margin-top: 20px;
}

.cardNueva {
	border-bottom: 2px solid #f4f4f4;
	margin-bottom: 15px;
	padding-bottom: 15px;
}

.cartImg {
	width: 80px;
	height: 80px;
	object-fit: contain;
	border-radius: 8px;
	overflow: hidden;
}

.cartExample {
	width: 80px;
	height: 80px;
	border-radius: 8px;
	overflow: hidden;
	margin-top: 0;
	margin-bottom: 0;
}
@media (min-width: 350px) {
	.cartImg, .cartExample {
		width: 95px;
		height: 95px;
	}
}

.pull-to-right {
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;
}

.iconToUp {
	margin: 0;
	width: 22px;
	height: 22px;
	font-size: 14px;
	display: flex;
	text-align: center;
	padding: 0;
	border-radius: 50%;
	box-shadow: none;
	line-height: 1;
	justify-content: center;
	align-items: center;
	background: #FFF;
	border: 1px solid #000;
	color: #000;
}

.finalPrice {
	font-weight: bold;
}

.formToUp {
	min-width: 110px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.quantityInfo {
	line-height: 1;
	position: relative;
	display: inline-block;
	margin: 0 10px;
	font-family: muli, sans-serif;
	top: -1px;
}

.containerForCartInfo {
	position: relative;
}
.botonEliminar {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 5px;
	color: #e33371;
	padding: 0;
	font-size: 24px !important;
	box-shadow: none;
	background: transparent;
}

.addmorecombinations {
	border: 1px solid #3880ff;
	text-decoration: none;
	text-align: center;
	line-height: 2;
	border-radius: 5px;
	display: flex;
	margin-top: 10px;
	width: 100%;
	max-width: 115px;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.padding-16 {
	padding-bottom: 16px !important;
}

.cardProduct {
	margin-bottom: 15px;
	box-shadow: 8px 8px 16px #ddd,-8px -8px 16px #fff !important;
}

.cardProduct.InfoDetail {
	margin-top: 30px;
}

.footerInfo {
	padding: 10px 15px;
}
.margin-top-25 {
	margin-top: 25px;
}

.cartfooterbutton {
	height: 45px;
	background: #404040;
	background-image: none;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	border-radius: 5px;
	font-weight: bold;
	box-shadow: none;
	border-radius: 50px;
	color: #FFF;
	font-family: muli, sans-serif;
}

.cartfooterbutton ion-icon {
	font-size: 24px;
	margin-right: 5px;
}

.whatsappButton {
	color: #FFF;	
	background: #21c59f;
}

.whatsappButton.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.addToCart {
	width: 100%;
	text-align: center;
	display: flex;
	background: #bdbdbd;
	height: 38px;
	margin-bottom: 20px;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	font-size: 17px;
	color: #FFF;
	box-shadow: none !important;
	font-family: muli, sans-serif;
}

.addToCart ion-icon {
	margin-right: 4px;
	font-size: 22px;
}
section.productInfo {
    padding-left: 10px;
    padding-right: 10px;
}

.headerProd img {
	height: 30px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
}

.headerProd ion-toolbar {
	--border-width: 0 !important;
	margin-bottom: 0;
	--background: transparent;
	--ion-color-base: transparent !important;
}


.headerProd ion-back-button {
	color: #000;
	font-size: 15px;
}

.cartHeader {
	color: #000 !important;
	font-size: 25px !important;
	width: auto !important;
	min-width: 1px !important;
	padding: 0 !important;
	position: relative;
	top: 2px;
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	background: #bbbaba;
	opacity: 0.5;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: #c94edb;
	opacity: 1;
}

.swiper-container {
	overflow: initial;
}

.swiper-pagination.swiper-pagination-bullets {
	bottom: -25px;
}

#precioProducto {
	font-weight: bolder;
	font-size: 18px;
	margin-top: 0;
	line-height: 1;
	display: block;
}

#precioProducto small {
	margin-left: 10px;
	font-size: 17px;
	position: relative;
	font-weight: 600;
	color: #999;
}

#productPrice {
	margin-bottom: 3px;
}


#precioProducto small:before {
	content: "";
	width: 100%;
	height: 1px;
	background: #999;
	display: block;
	position: absolute;
	top: 55%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.descriptionText {
	text-align: justify;
	font-size: 13px !important;
	font-weight: 300 !important;
	margin-top: 7px !important;
	color: #444 !important;
	line-height: 22px !important;
	max-height: 88px;
	overflow: hidden;
}
.descriptionText.sinlimite {
	max-height: none;
}
.descriptionText.abierto {
	max-height: none;
}

#productPrice {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: muli, sans-serif;
	color: #444;
}

.headerProd {
	padding-left: 5px;
	padding-right: 5px;
}

.MuiButtonGroup-contained {
	box-shadow: none;
}

.inputCirculos {
	display: flex;
	width: 100%;
}

.selectColor {
	width: 100%;
	height: 40px;
	border-radius: 50px;
	border: 2px solid #000;
	padding: 0 10px;
}


.textoCuantosCarritos {
	padding-left: 15px;
	padding-right: 15px;
	font-size: 13px !important;
	color: #CCC;
}

.tituloname {
	font-weight: bold;
	line-height: 1.2;
}

.infomini {
	font-size: 12px !important;
	font-weight: normal !important;
	color: rgba(0,0,0,0.5) !important;
}

.MuiFormControl-root {
	width: 100%;
}
.MuiFormControl-root + .MuiFormControl-root {
	margin-top: 35px;
	width: 100%;
}

.nextTo {
    min-width: 70%;
    height: 40px;
    position: absolute;
    top: 10px;
    border-width: 0;
    border-left: 1px solid #c4c4c4;
    padding-left: 15px;
    right: 5px;
    background: #FFF;
    z-index: 10;
    max-width: 70%;
    outline: none;
    -webkit-appearance: none;
}

.width100 {
	width: 100%;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #444 !important;
}

.MuiFormLabel-colorSecondary.Mui-focused {
	color: #444 !important;
}

.infoAsesor, .infoAsesor span {
	margin-top: 35px;
	font-size: 14px;
	color: #888;
	line-height: 1.25;
	overflow: hidden;
}

.infoAsesor span {
	margin-top: 0;
}

.MuiFormControlLabel-root {
	margin-right: 0;
}

.infoAsesor svg {
	float: left;
	margin-right: 10px;
}

button.cartfooterbutton {
	width: 100%;
	font-size: 20px;
	margin-top: 10px;
	margin-bottom: 15px;
}

button.cartfooterbutton:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.cartHeader .overexposed {
	right: -4px;
	width: 18px;
	height: 18px;
	font-size: 10px;
	top: -5px;
}

.slick-dots li button:before {
	font-size: 10px !important;
}
.slick-dots {
	bottom: 10px !important;
}

.botonactivado input:checked + label {
	background: #d7d6d6;
	border-color: #d7d6d6;
	width: 250%;
	height: 150%;
	max-width: none;
	display: flex;
	color: #000;
}

.alert-button-group * {
	color: #000;
}

.alert-radio-inner {
	border-color: #000 !important;
}

[aria-checked="true"] .alert-radio-label {
	color: #000 !important;
}

.modal-wrapper {
	min-height: 100% !important;
	--border-radius: 0;
}

.contenedorSlider {
	margin-left: -15px;
	margin-right: -15px;
}


.contenedorSlider ion-fab > ion-fab-button {
    --background: #000;
    --box-shadow: none;
    width: 40px;
    --color: #fff;
    height: 40px;
}

.contenedorSlider ion-fab > ion-fab-button ion-icon {
    font-size: 25px;
    position: relative;
    left: -2px;
}

.contenedorSlider ion-fab-list {
	right: -7px;
    margin-top: 50px;
}

.contenedorSlider ion-fab-list ion-fab-button {
	--background: #FFF !important;
	--color: #000;
}

.react-share__ShareButton {
	font-size: 22px !important;
	display: flex;
}

.MuiTypography-body1,
.MuiTypography-body2,
.MuiTypography-h3,
.MuiTypography-h2,
.MuiTypography-h1,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
	font-family: muli, sans-serif !important;
	color: #444;
}


.buscadorContainer {
	position: relative;
}

.buscadorContainer ion-icon {
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
}

.totales {
	height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 15%;
}

.totales.buscador {
	min-width: 65%;
}

#InfoCategories > .totales:first-child {
    min-width: 20% !important;
}

.buscadorContainer input {
	height: 40px;
	border-radius: 50px;
	border: 1px solid #444;
	padding-left: 40px;
	width: 100%;
	font-size: 13px;
}

.filtros {
	background: transparent;
	border-width: 0;
	font-size: 22px;
	padding-left: 0;
	padding-right: 0;
}

.totales ion-label {
    font-size: 20px !important;
	margin-right:0 !important;
}
.totales ion-select {
	position: absolute;
	opacity: 0;
	left: -15px;
	width: 50px;
	max-width: none;
	padding-left: 0;
	padding-right: 0;
	z-index: 100;
}

.totales ion-label {
	text-align: center;
}


ion-select-popover ion-label {
	font-size: 12px !important;
}

.ContainerForButtonViewMore ion-button {
	--background: #FFF;
	padding-left: 25px;
	padding-right: 1px;
}
br {
	display: block;
}
.ContainerForButtonViewMore {
	text-align: right;
	margin-top: -2px;
	position: relative;
}
.ContainerForButtonViewMore ion-button:before {
	content: "";
	width: 100%;
	height: 1px;
	background: #dfdfdf;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

.nohayproductos {
	width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 300px;
	margin-top: 15%;
}

.textareaProducto {
	width: 100%;
	border-radius: 4px;
	border: 1px solid #000;
	padding: 10px 10px;
	resize: none;
	height: 60px;
	box-shadow: none !important;
	outline: none !important;
}
.descriptionText.homepage {
	line-height: 15px !important;
	font-size: 12px !important;
	height: 30px;
	text-align: justify !important;
	margin-top: 0 !important;
	margin-bottom: 10px !important;
}