* {
	--background-activated: inherit;
}
.nuevocuadro {
	color: #000;
	text-decoration: none;
	box-shadow: none !important;
	border-width: 0 !important;
}
.botonportada {
	width: 100%;
	--border-radius: 0;
	margin: 0;
	--background: #F8F8F8;
	border-bottom: 3px solid #CECECE;
	--padding-bottom: 0;
	--padding-top: 0;
	--padding-end: 0;
	--padding-start: 0;
	position: relative;
}

.botonColumnas svg .a {
	stroke: #CECECE;
}

.botonportada ion-img {
	max-width: 50px;
}

#segundaLinea {
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
}

.logoUploaderContainer {
	position: relative;
	width: 125px;
	height: 85px;
}

.logoUploader {
	--background: #FFF;
	border: 3px solid #CECECE;
	border-radius: 50%;
	--border-radius: 50%;
	margin: 0;
	width: 125px;
	height: 125px;
	position: absolute;
	left: 0;
	top: -40px;
	--padding-bottom: 0;
	--padding-top: 0;
	--padding-end: 0;
	--padding-start: 0;
	overflow: hidden;
	background: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tituloPrincipal {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 22px;
	font-family: muli, sans-serif;
	margin-top: 10px;
	text-align: center;
	font-weight: bold;
}

#InfoCategories {
	display: flex;
	justify-content: space-evenly;
	text-align: center;
}

.totales h3 {
	text-transform: uppercase;
	color: #757575;
	line-height: 1;
	margin: 0;
	font-size: 8px;
	font-family: muli, sans-serif;
	font-weight: 600;
}

.totales p {
	margin: 0;
	margin-top: 0px;
	font-weight: 700;
	font-size: 20px;
	line-height: 1;
	font-family: muli, sans-serif;
}
.filtroColumnas {
	display: flex;
	justify-content: space-around;
	padding-left: 16px;
	padding-right: 16px;
	align-items: center;
	border: 2px solid #e2e6ec;
	border-left-width: 0;
	border-right-width: 0;
	margin: 10px auto 10px;
}

.botonColumnas {
	background: transparent;
	--background: transparent;
	border: 0;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0;
	outline: none !important;
	--border-radius: 50%;
	overflow: hidden;
	box-shadow: none;
	--box-shadow: none;
	--padding-start: 2px;
	--padding-end: 2px;
	--padding-top: 2px;
	--padding-bottom: 2px;
}

.botonColumnas.tres svg {
	max-width: 28%;
}

.botonColumnas.activo {
	border: 1px solid #f0f0f0;
}

.logoActual {
	width: 100%;
	position: static;
	border-radius: 50%;
	--border-radius: 50%;
	height: 125px;
	width: 125px;
}

.botonportada .portadaActual {
	width: 100%;
	max-width: none;
	opacity: 1;
}

.uploaderInput {
	display: none !important;
}

#iconosDerecha {
	display: flex;
	justify-content: center;
	align-items: center;
}

#iconosDerecha a {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 2px;
}
#TiendaWhatsContainer {
	display: flex;
	align-items: center;
}

#TiendaWhats {
	background: #CECECE;
	border-radius: 50px;
	line-height: 12px;
	font-size: 11px;
	max-width: 90px;
	text-align: center;
	font-weight: bold;
	color: #FFF;
	padding: 4px 0;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	min-width: 90px;
	font-family: muli, sans-serif;
}

.ChangeTitle {
	margin: 0;
	margin-bottom: -15px;
	--background: tr;
	--box-shadow: none;
	display: block;
	max-width: 55px;
	margin-left: auto;
	margin-right: 20px;
	margin-top: -20px;
}

ion-input.tituloPrincipal {
	border-bottom: 1px solid #000;
	margin-bottom: 15px;
	max-width: 90%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
}

.botonGuardarTitulo {
	--border-radius: 0;
	width: 100%;
	--background: tra;
	line-height: 1;
	--line-height: 1;
	max-width: 191px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	color: #a4a4a4;
	border: 2px solid #a4a4a4;
	border-radius: 50px;
	font-weight: bold;
	height: 45px;
	font-size: 14px;
}

.botonGuardarTitulo ion-img {
	max-width: 20px;
	margin-left: 5px;
}

.hiddenforce {
	display: none !important;
}

.categoriesContPrincipal {
	overflow: hidden;
}
.categoriesContainer {
	padding-top: 0;
	display: flex;
	overflow: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	padding-left: 5px;
	padding-right: 5px;
}
.categoryItem {
	min-width: 90px;
	max-width: 90px;
	position: relative;
	display: inline-block;
	padding-right: 0;
	text-align: center;
	outline: none;
	/* height: auto; */
	--background: transparent;
	--box-shadow: none;
	overflow: hidden;
	--padding-start: 0;
	--padding-end: 0;
	margin: 0;
	border-radius: 50%;
	min-height: 90px;
	max-height: 90px;
	margin-right: 20px;
}

.imagenCategory {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	object-fit: cover;
	background: #f5f5f5;
	overflow: hidden;
}

ion-button.categoryItem:not(.editable) .imagenCategory {
	object-fit: fill;
	width: 80px;
	height: 80px;
	overflow: hidden;
	border-radius: 50%;
}

.categoryTitle {
	font-size: 14px;
	color: gray;
	text-align: center;
	padding-right: 12px;
	line-height: 1.5;
	margin: 0;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
	max-width: 95px;
	font-family: muli, sans-serif;
}

.productosPrincipal {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 1%;
	padding-right: 1%;
	margin-top: 15px;
}
.una.productosPrincipal {
    padding-left: 10px;
    padding-right: 10px;
}
.productosPrincipal.admin {
	margin-top: 10px;
	margin-bottom: 40px;
}
.nuevocuadro {
	width: 100%;
	height: auto;
	position: relative;
	margin-bottom: 2%;
	margin-top: 2%;
}

.dos .nuevocuadro {
	width: 46%;
	margin-left: 2%;
	margin-right: 2%;
	margin-top: 1.5%;
	margin-bottom: 1.5%;
}

.tres .nuevocuadro {
	width: 32%;
	margin-top: 1.5%;
	margin-bottom: 1.5%;
}

.cuadrosFinales {
	min-height: 385px;
}

.dos .cuadrosFinales {
	min-height: 225px;
}

.tres .cuadrosFinales {
	min-height: 165px;
}

.imgcontainer {
	width: 100%;
	position: relative;
	box-shadow: 0 0 2px rgba(52,58,67,.1),0 2px 5px rgba(52,58,67,.08),0 5px 15px rgba(52,58,67,.08);
	border-radius: 4px;
	overflow: hidden;
}

.imgcontainer:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.imgcontainer ion-img {
	position: absolute;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	object-fit: contain;
	object-position: center;
}

.contenedorCuadrado {
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logoUploader.cuadrado {
	margin: 0;
	--border-radius: 10px;
	border-width: 0;
	--box-shadow: none;
	width: 100%;
	height: 100%;
}

.logoUploader.square {
	border-radius: 10px;
	--border-radius: 10px;
	border-width: 0;
	width: 150px;
	height: 150px;
	border-width: 1px;
	--box-shadow: none;
}

.inlineButton.rect {
	top: 0;
	font-size: 15px;
}
.sinBorde {
	--inner-border-width: 0;
}

.mt-5 {
	margin-top: 40px;
}

.contenedorAtributos {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin-bottom: 15px;
}

.contenedorAtributos > ion-item {
	flex: 1;
	--inner-border-width: 0;
}

.botonRedondo {
	border-radius: 50%;
	display: flex;
	background: #f9f9f9;
	padding: 15px 10px 10px;
	position: relative;
	top: -20px;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	max-width: 60px;
	max-height: 60px;
}
.botonRedondo.sinTop {
	top: 0;
}

.alertdanger {
	padding-left: 15px;
	padding-right: 15px;
	color: #e63333;
	font-size: 12px;
	line-height: 1;
}

.itemTitleCombi {
	width: 100%;
	display: flex;
	align-items: center;
	border-bottom: 2px solid #a4a4a4;
	text-align: center;
	line-height: 1;
	height: auto !important;
	max-width: 40%;
}

.inputQuantity ion-input {
	border: 2px solid #a4a4a4;
	width: 65px;
	--padding-start: 5px !important;
	--padding-end: 0;
}

#realQtyCont {
	width: 100%;
}

.logoUploader.cuadrado.item ion-img {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	object-fit: fill;
}

.logoUploader.cuadrado ion-icon {
	color: #858585;
	font-size: 50px;
	width: 40px;
	text-align: center;
	display: flex;
	justify-content: center;
	position: absolute;
	background: #FFF;
	height: 40px;
	border-radius: 50%;
	padding: 10px;
	box-shadow: 0 0 5px #858585;
	top: 15%;
}

.infoContentEdit {
	position: absolute;
	width: 100%;
	bottom: 0;
	background: rgb(35, 35, 47);
}

.infoContentEdit h4 {
	font-size: 12px;
	margin: 5px 0 3px;
	text-align: left;
	padding: 0 10px;
	font-weight: normal;
}

.infoContentEdit h5 {
	margin: 5px 0 4px;
	line-height: 1;
	font-size: 10px;
	text-align: left;
	padding: 0 10px;
	display: flex;
	justify-content: space-between;
}

.separadorViewAll {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 1%;
	padding-right: 1%;
	margin-top:15px;
}

.separadorViewAll h3 {
	margin: 0;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 1;
}

.botonsombreado {
	box-shadow: none;
	border-radius: 50px;
	--ion-color-light: #FFF;
	text-transform: none;
	--border-radius: 50px;
	--box-shadow: 0 0 5px #d2d2d2;
}

.inforproduct h4 {
	margin-top: 8px;
	margin-bottom: 3px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 90%;
	padding: 0 2px;
	font-size: 17px;
	line-height: 1.5;
	font-family: muli, sans-serif;
	color: #444;
	font-weight: 600;
}

.inforproduct h5 {
	padding: 0 2px;
	color: #000;
	font-weight: bold;
	font-size: 15px;
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	font-family: muli, sans-serif;
}

.inforproduct h5 .descuento {
	margin-left: 5px;
	font-size: 13px;
	font-weight: normal;
	position: relative;
	color: #AEB5C0;
	font-weight: normal;
}

.inforproduct h5 .descuento:before {
	content: "";
	width: 100%;
	height: 1px;
	background: #AEB5C0;
	position: absolute;
	top: 50%;
}

.tres .inforproduct h5 span {
	font-size: 12px;
}
.tres .inforproduct h5 .descuento {
	display: inline-block;
	margin-left: 2px;
	font-size: 12px;
	letter-spacing: -1px;
}

@media (max-width: 325px) {
	.tres .inforproduct h5 .descuento {
		margin-left: 1px;
	}
}

.tres.productosPrincipal {
	justify-content: flex-start;
}

.tres.productosPrincipal > a {
	margin: 0 0.666%;
}

.whatsappButtonGlobal {
	width: 42px;
	height: 42px;
	background: rgb(48,191,57);
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	font-size: 25px;
	overflow: hidden;
	line-height: 1;
	color: #FFF;
}

.micatalogoButton, .micatalogoButton svg {
	width: 42px;
	height: 42px;
}

.svgCircle {
    fill:none;
    stroke:#8a3ab9;
    stroke-linecap: round;
    stroke-width:2;
    stroke-dasharray: 1;
    stroke-dashoffset: 0;
    animation: stroke-draw 3s ease-out 1 alternate; 
	position: absolute;
	width: 110px;
	height: 110px;
  }

@keyframes stroke-draw {
  from{
    stroke-dasharray: 1;
  }
  to{
    transform:rotate(180deg);
    stroke-dasharray: 8;
  }
}

.subCategorias1 {
	display: flex;
	padding-top: 0;
	display: flex;
	overflow: scroll;
	overflow-y: scroll;
	overflow-y: hidden;
	-ms-overflow-style: none;
	padding-left: 5px;
	padding-right: 5px;
	box-shadow: 0 3px 6px #e6e6e6;
	margin-bottom: 25px;
	margin-top: 15px;
	border-top: 2px solid #e6e6e6;
}

@media (max-width: 991px) {
	.subCategorias1 {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}

.subCategorias1 + .subCategorias1 {
	margin-top: -25px;
}
@media (max-width: 991px) {
	.subCategorias1::-webkit-scrollbar,
	.categoriesContainer::-webkit-scrollbar {
		display: none;
	}
}

.subCategorias1 > a {
	display: flex;
	padding: 0;
	margin: 0;
}

.subCategorias1 > a {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	height: 45px;
	padding: 0 20px;
	position: relative;
	justify-content: center;
	list-style: none;
	background: #FFF;
	border-width: 0;

	text-align: center;
	outline: none;
	--background: transparent;
	--box-shadow: none;
	overflow: hidden;
	--padding-start: 0;
	--padding-end: 0;
	margin: 0;
	color: gray;
	font-size: 14px;
	font-family: muli, sans-serif;
	text-decoration: none !important;
}

.subCategorias1 > a:before {
	content: "";
	display: block;
	height: 3px;
	width: 0%;
	background-color: rgb(127, 127, 127);
	position: absolute;
	bottom: 0;
	right: 0;
	left: auto;
	transition: all .5s;
}

.subCategorias1 > a.activo:before {
	width: 100%;
	left: 0;
	right: 0;
	transition: all .5s;
	-webkit-transition: all .5s;
}

.Subcategorias0 {
	background: #FFF;
	z-index: 10000;
	position: sticky;
	top: 0;
}


.subCats h4, .subCats h5 {
	padding-left: 10px;
	padding-right: 10px;
    font-family: 'muli', sans-serif;
    margin: 0 auto 15px;
    line-height: 1.5;
}

.subCats h4 {
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid rgb(226, 226, 226);
	border-top: 1px solid rgb(226, 226, 226);
}

.subCats h5 {
	margin-bottom: 5px;
}

.alertanohay {
	margin-left: 10px;
	margin-right: 10px;
	background: rgb(226,226,226);
	color: #000;
	font-family: 'muli', sans-serif;
	margin-bottom: 25px;
	display: flex;
	height: 45px;
	padding: 0 10px;
	align-items: center;
}

.mostradod {
	display: flex !important;
}

.hidden {
	display: none;
}

.nuevoProductoContainer h2 {
	font-size: 20px;
	font-family: 'muli', sans-serif;
	padding: 0 10px;
	text-transform: uppercase;
	font-weight: bold;
	margin: 0;
	color: #7f7f7f;
}

.nuevoProductoContainer h3 {
	font-size: 16px;
	padding: 0 10px;
	margin: 5px 0 20px;
	color: #7f7f7f;
}

.alerta {
	margin: -15px 10px 25px;
    background: #ececec;
    padding: 15px 10px;
    border-radius: 5px;
    font-family: 'muli', sans-serif;
    line-height: 1;
}

.alerta.grande {
    margin-top: 5px;
    margin-bottom: 15px;
}


.fixedReal ion-fab-button {
	height: 40px;
	width: 40px;
	--background: rgba(0,0,0,0.5);
}

.fixedReal ion-fab-list ion-fab-button {
	--background: #FFF !important;
	--color: #000;
}

.fixedReal ion-fab-button ion-icon {
	font-size: 24px;
}

.fixedReal ion-fab-list {
	margin: 50px 0 0;
	left: -7px;
}